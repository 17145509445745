/* JS */
/* react component */
import React from 'react'
import ReactDOM from 'react-dom'
import igLogo from '../../images/ig.svg';
import linkedin from '../../images/iconmonstr-linkedin-1.svg';
import maik from '../../images/iconmonstr-linkedin-1.svg';
import ModalVideo from 'react-modal-video'

/* third part component */
import { Link } from "gatsby"
/* custom component */



import 'animate.css'
import './Menu.css'
import './GsapMenu.scss'



const COLORS = [
	'#ff5252',
	'rgba(244, 145, 49, 1.000)',
	'#f1c40f',
	'#63cdda',
	'#546de5',
]


class Menu extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			opacity: 0,
			width: 0,
			showReelOpen: false,
		}

		this.handleOnScroll = this.handleOnScroll.bind(this);

		this.handleOnButtonClick = this.handleOnButtonClick.bind(this);
		this.handleOnButtonMouseEnter = this.handleOnButtonMouseEnter.bind(this);
		this.handleOnButtonMouseLeave = this.handleOnButtonMouseLeave.bind(this);
		this.handleOnOpenShowReel = this.handleOnOpenShowReel.bind(this);
		this.handleOnCloseShowReeel = this.handleOnCloseShowReeel.bind(this);

		this.handleOnWindowSizeChange = this.handleOnWindowSizeChange.bind(this);
	}

	componentDidMount() {
		this.handleOnWindowSizeChange();
		window.addEventListener('resize', this.handleOnWindowSizeChange);
		ReactDOM.findDOMNode(this).addEventListener('mousewheel', this.handleOnScroll);
		ReactDOM.findDOMNode(this).addEventListener('DOMMouseScroll', this.handleOnScroll);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.open === false && this.props.open === true) {
			ReactDOM.findDOMNode(this).addEventListener('mousewheel', this.handleOnScroll);
			ReactDOM.findDOMNode(this).addEventListener('DOMMouseScroll', this.handleOnScroll);
		} else if (prevProps.open === true && this.props.open === false) {
			ReactDOM.findDOMNode(this).removeEventListener('mousewheel', this.handleOnScroll);
			ReactDOM.findDOMNode(this).removeEventListener('DOMMouseScroll', this.handleOnScroll);
		}
	}

	componentWillUnmount() {
		ReactDOM.findDOMNode(this).removeEventListener('mousewheel', this.handleOnScroll);
		ReactDOM.findDOMNode(this).removeEventListener('DOMMouseScroll', this.handleOnScroll);
		window.removeEventListener('resize', this.handleOnWindowSizeChange);
	}

	handleOnScroll(event) {
		event = event || window.event;
		event.preventDefault();
		event.stopPropagation();
		event.returnValue = false;
	}

	handleOnButtonClick(event, text) {
		event.stopPropagation();
		this.setState({
			bgAnimation: 'fadeOut',
			btAnimation: 'rotateOutUpRight',
		});
		setTimeout(() => {
			this.setState({
				open: false
			});
			this.props.onButtonClick(text);
		}, 250);
	}

	handleOnButtonMouseEnter(event) {
		if (!this.props.animating) {
			let button = event.target.parentNode.parentNode;

			let arr = button.className.split(" ");
			if (arr.indexOf('hover') === -1) {
				button.className += ' hover';
			}

			// button.style.width = (button.offsetWidth + 20) + 'px';
			// button.style.height = (button.offsetHeight + 20) + 'px'
		}
	}

	handleOnButtonMouseLeave(event) {
		if (!this.props.animating) {
			let button = event.target.parentNode.parentNode;

			button.classList.remove("hover");
			// button.style.width = (button.offsetWidth - 20) + 'px';
			// button.style.height = (button.offsetHeight - 20) + 'px';
		}
	}

	handleOnWindowSizeChange() {
		this.setState({ width: window.innerWidth });
	};

	handleOnCloseShowReeel() {
		this.setState({ showReelOpen:false });
	}
	handleOnOpenShowReel() {
		this.setState({ showReelOpen:true });
	}

	openShowReel(){

	}

	render() {
		const isMobile = this.state.width <= 1024;

		const bgAnimation = this.props.open ? 'fadeIn' : 'fadeOut';
		const btAnimation = this.props.open ? 'rotate-in-down-right' : 'rotate-out-up-right';


		return (

			<React.Fragment>
   <ModalVideo channel='youtube'  controls="0"	 isOpen={this.state.showReelOpen} videoId="cSel9iy7PIQ" onClose={() => this.handleOnCloseShowReeel()} />
            {/**
			 * TODO SHOW REEL SPOSTARE
			 */}

			<div id="menu" className="menu">

				<div className="menu__body">

					<div className="container">
						<div className="row">

							<div className="col-xs-12 col-sm-12 col-md-6 mobile" >
								<ul className="menu__voices">
									<Link
										to="/"
										style={{
											textDecoration: `none`,
										  }}>
										<li>
											<h5 className="voice">Home</h5>
										</li>
									</Link>
								<li onClick={() => this.handleOnOpenShowReel()} >
									<h5 className="voice">Show Reel</h5>
								</li>
								<Link
										to="/servizi"
										style={{
											textDecoration: `none`,
										  }}>
										<li>
											<h5 className="voice">Servizi</h5>
										</li>
									</Link>
									<Link
										to="/contatti"
										style={{
											textDecoration: `none`,
										  }}>
										<li>
											<h5 className="voice">Contatti</h5>
										</li>
									</Link>
									</ul>
						</div>

						<div className="col-xs-12 col-sm-12 col-sm-push-12 col-md-6">
							<div className="menu__addressess">
								<div className="address">
									<a href="mailto:info@alfatauristudio.com">

										<svg version="1.1" fill='#3E3E3E' width="22.414" height="22.414" id="Livello_3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
											viewBox="0 0 24 23"  >

											<g >
												<g >
													<path fill='#3E3E3E' d="M8,23c-0.2,0-0.3,0-0.5-0.1C7.2,22.7,7,22.4,7,22v-3.3C2.9,17.5,0,13.8,0,9.5C0,7,1,4.6,2.8,2.8
			C4.6,1,6.9,0,9.5,0h5C19.7,0,24,4.3,24,9.5S19.7,19,14.5,19h-0.2l-5.7,3.8C8.4,22.9,8.2,23,8,23z M9.5,2c-2,0-3.9,0.8-5.3,2.2
			C2.8,5.6,2,7.5,2,9.5c0,3.6,2.6,6.7,6.1,7.3C8.6,17,9,17.4,9,17.9v2.3l4.4-3c0.2-0.1,0.4-0.2,0.6-0.2h0.5c4.1,0,7.5-3.4,7.5-7.5
			S18.6,2,14.5,2H9.5z"/>
												</g>
											</g>
										</svg>



										info@alfatauristudio.com</a>
								</div>
								<div className="address">
									<a  href="https://www.google.com/maps/dir//Via+Renato+Simoni,+30,+00157+Roma+RM/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x132f63d052f4e667:0xeb9559b6a5f8b205?sa=X&ved=2ahUKEwiO67Xog8juAhWhPOwKHdaTC3UQwwUwAHoECAgQAw" target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="22.414" viewBox="0 0 22.414 22.414">
											<g id="search" transform="translate(1 1)">
												<path id="Path_5408" data-name="Path 5408" d="M8,0a8,8,0,0,1,8,8,8.152,8.152,0,0,1-2.343,5.657A7.8,7.8,0,0,1,8,16,8,8,0,0,1,8,0Z" fill="none" stroke="#3e3e3e" stroke-linecap="round" stroke-width="2" />
												<path id="Path_3" data-name="Path 3" d="M22,22l-6-6" transform="translate(-2 -2)" fill="none" stroke="#3e3e3e" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
											</g>
										</svg>

											00157 Via Renato Simoni 30 <br></br> Roma RM</a>
								</div>
								<div className="address">
									<a href="tel:+39 3889844609">
										<svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="22.414" viewBox="0 0 16 24">
											<g id="camera-dslr" transform="translate(1 23) rotate(-90)">
												<path id="Path_1" data-name="Path 1" d="M6.806,19H3a2,2,0,0,1-2-2V7A2,2,0,0,1,3,5H21a2,2,0,0,1,2,2V17a2,2,0,0,1-2,2H6.806" transform="translate(-1 -5)" fill="none" stroke="#3e3e3e" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
												<path id="Path_2" data-name="Path 2" d="M19,8.99V9" transform="translate(-1 -5)" fill="none" stroke="#3e3e3e" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
											</g>
										</svg>


																	+39 3889844609</a>

								</div>


							</div>
						</div>

						<div className="col-xs-12 col-sm-12  col-md-6 desktop" >
						<ul className="menu__voices">
									<Link
										to="/"
										style={{
											textDecoration: `none`,
										  }}>
										<li>
											<h5 className="voice">Home</h5>
										</li>
									</Link>
								<li onClick={() => this.handleOnOpenShowReel()} >
									<h5 className="voice">Show Reel</h5>
								</li>
								<Link
										to="/servizi"
										style={{
											textDecoration: `none`,
										  }}>
										<li>
											<h5 className="voice">Servizi</h5>
										</li>
									</Link>
									<Link
										to="/contatti"
										style={{
											textDecoration: `none`,
										  }}>
										<li>
											<h5 className="voice">Contatti</h5>
										</li>
									</Link>
									</ul>
						</div>

					</div>

					<div className="menu__socials">
						<a id="instagram" href="ttps://www.instagram.com/alfatauristudio" target="blank" >
							<svg fill="#9F9FA9" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg>
							<span>Instagram</span>
						</a>

						<a id="linkedin" href="https://www.linkedin.com/company/alfatauri-studio" target="blank">
							<svg fill="#9F9FA9" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" /></svg>
							<span>Linkedin</span>

						</a>


					</div>
				</div >
			</div>
				</div >
				</React.Fragment>

		);
	}
}

export default Menu;



{/* 

				<div onClick={this.props.onOutsideClick} className={"menu-background animated faster " + bgAnimation + (this.props.open ? ' open' : '')}>

				<div className="menu-buttons-container">

					<Link to="/contatti/" key={"button-" + 0} className={"menu-button " + btAnimation} title="Contatta l'agenzia di design">
						<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={'0 0 80 80'}>
							<defs>
								<path id={"curve-" + 0} strokeWidth="0" stroke="#ffffff" fill="none" d={'M40,80a40,40 0 0,0 0,-80a40,40 0 0,0 0,80'} />
							</defs>
							<circle cx="40" cy="40" r="40" strokeWidth="0" fill={COLORS[0]} //onClick={(event) => { this.handleOnButtonClick(event, 'contatti'); }}
								onMouseEnter={this.handleOnButtonMouseEnter} onMouseLeave={this.handleOnButtonMouseLeave} />
							<text textAnchor={isMobile ? "end" : "middle"}>
								<textPath xlinkHref={"#curve-" + 0} startOffset={isMobile ? (99 - (0 * 0.4)) + "%" : "85%"}>
									contatti
									</textPath>
							</text>
						</svg>
					</Link>

					<Link to="/team/" key={"button-" + 1} className={"menu-button " + btAnimation} title="Il team di esperti di Alfatauri Studio">
						<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={'0 0 80 80'}>
							<defs>
								<path id={"curve-" + 1} strokeWidth="0" stroke="#ffffff" fill="none" d={'M40,80a40,40 0 0,0 0,-80a40,40 0 0,0 0,80'} />
							</defs>
							<circle cx="40" cy="40" r="40" strokeWidth="0" fill={COLORS[1]} //onClick={(event) => { this.handleOnButtonClick(event, 'team'); }}
								onMouseEnter={this.handleOnButtonMouseEnter} onMouseLeave={this.handleOnButtonMouseLeave} />
							<text textAnchor={isMobile ? "end" : "middle"}>
								<textPath xlinkHref={"#curve-" + 1} startOffset={isMobile ? (99 - (1 * 0.4)) + "%" : "85%"}>
									team
                                </textPath>
							</text>
						</svg>
					</Link>
					<Link to="/servizi/" key={"button-" + 2} className={"menu-button " + btAnimation} title="Servizi web e di design forniti da Alfatauri Studio">
						<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={'0 0 80 80'}>
							<defs>
								<path id={"curve-" + 2} strokeWidth="0" stroke="#ffffff" fill="none" d={'M40,80a40,40 0 0,0 0,-80a40,40 0 0,0 0,80'} />
							</defs>
							<circle cx="40" cy="40" r="40" strokeWidth="0" fill={COLORS[2]} //onClick={(event) => { this.handleOnButtonClick(event, 'servizi'); }}
								onMouseEnter={this.handleOnButtonMouseEnter} onMouseLeave={this.handleOnButtonMouseLeave} />
							<text textAnchor={isMobile ? "end" : "middle"}>
								<textPath xlinkHref={"#curve-" + 2} startOffset={isMobile ? (99 - (2 * 0.4)) + "%" : "85%"}>
									servizi
                                </textPath>
							</text>
						</svg>
					</Link>
					<Link to="/home/" key={"button-" + 3} className={"menu-button " + btAnimation} title="Torna al portfolio">
						<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={'0 0 80 80'}>
							<defs>
								<path id={"curve-" + 3} strokeWidth="0" stroke="#ffffff" fill="none" d={'M40,80a40,40 0 0,0 0,-80a40,40 0 0,0 0,80'} />
							</defs>
							<circle cx="40" cy="40" r="40" strokeWidth="0" fill={COLORS[3]} //onClick={(event) => { this.handleOnButtonClick(event, 'home'); }}
								onMouseEnter={this.handleOnButtonMouseEnter} onMouseLeave={this.handleOnButtonMouseLeave} />
							<text textAnchor={isMobile ? "end" : "middle"}>
								<textPath xlinkHref={"#curve-" + 3} startOffset={isMobile ? (99 - (3 * 0.4)) + "%" : "85%"}>
									home
                                </textPath>
							</text>
						</svg>
					</Link>
					<div key="button-x" className={"menu-button " + btAnimation}>
						<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={'0 0 80 80'}>
							<circle cx="40" cy="40" r="40" strokeWidth="0" fill={COLORS[4]} />
						</svg>
					</div>
				</div>
							</div>

				*/}