import React from 'react'

import './ATCursor.css'

class ATCursor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (<div className="at-cursor"
            style={{
                height: (this.props.isPositionOutside ? 0 : this.props.isHolding ? 20 : 40),
                width: (this.props.isPositionOutside ? 0 : this.props.isHolding ? 20 : 40),
                top: this.props.position && this.props.position.y, left: this.props.position && this.props.position.x,
                margin: (this.props.isHolding ? '-10px' : '-20px') + ' 0 0 ' + (this.props.isHolding ? '-10px' : '-20px')
            }}></div>);
    }
};

export default ATCursor;