import React from 'react'

import './ContactButton.scss'

const ContactButton = (props) => {
    return (
    <button type="button" className="contact__button">
    </button>);
}

export default ContactButton;