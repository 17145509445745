/* JS */
/* react */
import React from "react"

/* third-party components */
import { Link } from "gatsby"
// import ReactCursorPosition from 'react-cursor-position'

/* custom components */
import ATCursor from './ATCursor/ATCursor'
import Menu from './Menu/Menu'
import CookieBanner from './CookieBanner/CookieBanner'
import Loader from './Loader/Loader'
import ContactButton from './ContactButton/ContactButton'



/* STYLE */

/* third part styles */
import 'bootstrap/dist/css/bootstrap.min.css'
/* custom styles */
import "./ATLayout.css"

import "../static/scss/cursor.scss"
/* CONST */

const logotype = require('../images/logotype.svg')

const pages = {
	SYSTEM: 'Home',
	SERVICES: 'Servizi',
	TEAM: 'Team',
	CONTACT: 'Contatti',
	FEEDBACK: 'Feedback',
	CASESTUDY: 'Case study'
}

// Basic cursor

// Cursor options with defaults


// Basic magnetic

// Magnetic options with default



class ATLayout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isMouseHolding: false,
			loading: true,
			//isMenuOpen: false,
			isMenuAnimating: false,
			isBannerVisible: false
		}

		this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
		this.handleOnMouseUp = this.handleOnMouseUp.bind(this);

		this.handleOnCookieOK = this.handleOnCookieOK.bind(this);
		this.handleOnLogoClick = this.handleOnLogoClick.bind(this);
		// this.handleOnMenuOpen = this.handleOnMenuOpen.bind(this);
		// this.handleOnMenuClose = this.handleOnMenuClose.bind(this);
		// this.handleOnMenuButtonClick = this.handleOnMenuButtonClick.bind(this);

		this.handleOnPositionChanged = this.handleOnPositionChanged.bind(this);
	}

	componentDidMount() {
		//window.addEventListener('load', this.handleOnLoaded);


		setTimeout(
			() => {
				this.setState({
					loading: false,
					isBannerVisible: true
				})
			},
			2000
		)
	}

	handleOnMouseDown(event) {
		this.setState({
			isMouseHolding: true
		});
		//console.log(event);
	}

	handleOnMouseUp() {
		this.setState({
			isMouseHolding: false
		});
	}

	handleOnCookieOK() {
		this.setState({
			isBannerVisible: false
		})
	}

	handleOnLogoClick() {
		this.handleOnMenuButtonClick('home');
	}

	

	handleOnPositionChanged(obj) {
		this.setState({ ...obj });
	}

 	render() {
		return (
			// <ReactCursorPosition onPositionChanged={this.handleOnPositionChanged}>
				<div className={"at at-" + this.props.title.split(' ').join('-').toLowerCase()}
				onMouseDown={this.handleOnMouseDown}
				onMouseUp={this.handleOnMouseUp}>
					{this.props.title !== pages.SYSTEM &&
					<header className="at-header" style={this.props.fixedHeader && {position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1}}>
						<Link to="/home/">
							<img className="logo" src={logotype} alt="Logotype" />
						</Link>
						{/* {this.state.screen !== pages.SYSTEM && <img className="logo star" src={logoStar} alt="Logo" />} */}
					</header>}
					
					{this.props.children}

					{/* <Hamburger open={this.state.isMenuOpen} //className={this.props.isMenuOpen ? 'open' : ''}
						onClick={!this.state.isMenuOpen ? this.handleOnMenuOpen : this.handleOnMenuClose} /> */}
					
					<Menu open={this.props.isMenuOpen} onOutsideClick={this.props.onOutsideMenuClick} />
					
					{this.props.title === pages.SYSTEM &&
					<CookieBanner visible={this.state.isBannerVisible} onOK={this.handleOnCookieOK} />}

					{this.props.title === pages.SYSTEM &&
					<Loader active={this.state.loading} />}

                    <ContactButton></ContactButton>
					{/* <ATCursor isHolding={this.state.isMouseHolding} position={this.state.position} isPositionOutside={this.state.isPositionOutside} /> */}
				</div>
			// </ReactCursorPosition> 
		)
	}
}

export default ATLayout;
