import React from 'react'

import './CookieBanner.css'

const CookieBanner = (props) => {

    let animation = 'fade'

    return (
        <div className={"cookie animated " + (props.visible ? animation + 'In' : 'out ' + animation + 'Out')}>
            Utilizziamo i cookie per essere sicuri che tu possa avere la migliore esperienza sul nostro sito. Se
            continui ad utilizzare questo sito noi assumiamo che tu ne sia felice.
            <button className="cookie-ok" onClick={props.onOK}>&#10003;Continua</button>
        </div>
    );
}

export default CookieBanner;