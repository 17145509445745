import React from 'react'

import './Loader.css'

const Loader = (props) => {
    return (
        <div className={"loader " + (props.active ? 'active' : '')} style={{position: props.absolute && 'absolute'}}>
            {/* <img src={require('../../images/loader.gif')} alt="Loading" /> */}
			<video
				autoPlay="autoplay"
				src={require('../../images/loader_at.mp4')}
				loop={false}
				muted
				type="video/mp4"
				style={{
					height: '20vw',
					position: 'absolute',
					top:'50%', left:'50%',
					transform: 'translate(-50%,-50%)',
					backgroundColor: 'white',
		
				}}
			/>
        </div>
    );
}

export default Loader;